import { createSlice } from "@reduxjs/toolkit";

export const resumeSlice = createSlice({
  name: "resume",
  initialState: {
    modalContent: null,
    education: null,
    skills: null,
    dataInput: {}, // Les données saisie
  },
  reducers: {
    setModalData: (state, actions) => {
      // ici les données de la modale pour modifier les information d'un diplome
      state.modalContent = actions.payload;
    },
    setEducation: (state, actions) => {
      // charger les données des diplômes ici
      state.education = actions.payload;
    },
    setSkills: (state, actions) => {
      // charger les données des competences ici
      state.skills = actions.payload;
    },
    //Initialiser l'objet en charges des saisie des inputs
    setDataInput: (state, actions) => {
      state.dataInput = actions.payload;
    },
    // Capturer et mettre à jour les valeur lors de l'evenement input
    updateInputValue: (state, actions) => {
      const { name, value } = actions.payload;
      state.dataInput[name] = value;
    },
    addOrUpdateEducation: (state, actions) => {
      const { id, newEducation } = actions.payload; // Les données à ajouter ou à mettre à jour
      if (!state.education) {
        // Si `education` est vide, initialisez-le comme un tableau
        state.education = [];
      }

      // Rechercher l'index de l'éducation avec le même ID
      const index = state.education.findIndex((edu) => edu.id === id);
      if (index !== -1) {
        // Si l'ID existe, mettez à jour l'objet
        state.education[index] = { ...state.education[index], ...newEducation };
      } else {
        // Si l'ID n'existe pas, ajoutez le nouvel objet
        state.education.push(newEducation);
      }
    },
    deleteEducationLocal: (state, actions) => {
      const id = actions.payload;
      state.education = state.education.filter((elem) => elem.id !== id);
    },
    addOrUpdateSkill: (state, actions) => {
      const { id, newSkill } = actions.payload; // Les données à ajouter ou à mettre à jour
      if (!state.skills) {
        // Si `education` est vide, initialisez-le comme un tableau
        state.skills = [];
      }

      // Rechercher l'index de l'éducation avec le même ID
      const index = state.skills.findIndex((edu) => edu.id === id);

      if (index !== -1) {
        // Si l'ID existe, mettez à jour l'objet
        state.skills[index] = { ...state.skills[index], ...newSkill };
      } else {
        // Si l'ID n'existe pas, ajoutez le nouvel objet
        state.skills.push(newSkill);
      }
    },
    deleteSkillLocal: (state, actions) => {
      const id = actions.payload;
      state.skills = state.skills.filter((elem) => elem.id !== id);
    },

    //Reinitialiser les données apres la soumission du formulaire
    resetValue: (state) => {
      state.dataInput = {};
      state.modalContent = {};
    },
  },
});

export const {
  setEducation,
  setSkills,
  setModalData,
  setDataInput,
  resetValue,
  updateInputValue,
  addOrUpdateEducation,
  addOrUpdateSkill,
  deleteEducationLocal,
  deleteSkillLocal,
} = resumeSlice.actions;
export default resumeSlice.reducer;
