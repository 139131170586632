import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateInputValue } from "../../../store/features/resume";
import { setIsSent, setModalMessage } from "../../../store/features/modalSlice";
//  les input pour la modal education, pour eviter les conflit sur le label
export function InputEducation({ name, label, type, value }) {
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch(updateInputValue({ name, value }));
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
  };
  return (
    <>
      <label
        className={`form-label floating-label ${isFocused && "focused"}  `}
        htmlFor={name}
      >
        {label}
      </label>

      {type === "textarea" ? (
        <textarea
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          name={name}
          id={name}
          className="form-control"
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        ></textarea>
      ) : (
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          name={name}
          type={type}
          value={value}
          id={name}
          className="form-control"
          onChange={(e) => handleChange(e.target.value)}
        />
      )}
    </>
  );
}
