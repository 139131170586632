import { Cards } from "../components/Home/Cards";
import { CardList } from "../components/Home/HomeCard";
import { HomePicture } from "../components/Home/HomePicture";
import { ModalContent } from "../components/Home/Modal/ModalContent";
import { cardData } from "../data/cardHome";
export function Home() {
  return (
    <div className="homeSection">
      <h2 className="sr-only">Home</h2>
      <Cards />
      <div className="d-flex w-100 content-gaphique-cards">
        <div className="list-inline d-flex cards-left">
          <h3>Home section</h3>
          <ul className="d-flex inner-home row">
            <HomePicture />
            <CardList data={cardData} />
            {/* lister les blogs ici à l'avenir */}
            {/* <HomeCV /> */}
          </ul>
        </div>
      </div>
      <ModalContent />
    </div>
  );
}
