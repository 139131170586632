import React from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  setIsApmtyInput,
  setIsSent,
  setModalMessage,
} from "../../store/features/modalSlice";
Modal.setAppElement("#root"); // Nécessaire pour l'accessibilité

export function CustomModal({
  title,
  onConfirm,
  isInputEditable,
  children,
  sending,
  resetValues,
}) {
  const isModalOpen = useSelector((state) => state.modal.isOpen);
  const isSent = useSelector((state) => state.modal?.isSent);
  const isEmptyInput = useSelector((state) => state.modal?.isEmptyInput);

  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
    dispatch(setIsApmtyInput(true));
    resetValues();
  };
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        contentLabel="Custom Modal"
        className="modal-dialog"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => handleCloseModal()}
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => handleCloseModal()}
            >
              Close
            </button>
            {!isInputEditable && (
              <button
                type="button"
                className="btn btn-primary "
                onClick={onConfirm}
                disabled={isSent || isEmptyInput}
              >
                <span className="pe-2">Confirm</span>
                {sending && <i className="fa-solid fa-spinner fa-spin "></i>}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
