import { useDispatch } from "react-redux";
import { openModal } from "../../store/features/modalSlice";
import { setDataInput, setModalData } from "../../store/features/project";

export function Button() {
  const element = {
    modalTile: "Ajouter un projet",
    method: "POST",
    function: "addOrUpdate",

    image: "",
    title: "",
    technologies: "",
    description: "",
    website: "",
    github: "",
  };
  const dataInput = {
    title: "",
    technologies: "",
    description: "",
    image: "",
    github: "",
    website: "",
  };
  const dispatch = useDispatch();

  const addFormation = () => {
    dispatch(setModalData(element));
    dispatch(setDataInput(dataInput));
    dispatch(openModal());
  };

  return (
    <div className="d-flex boutons">
      <button className="btn btn-secondary" onClick={() => addFormation()}>
        Ajouter une projet
      </button>
    </div>
  );
}
