export async function getAll(accessToken) {
  try {
    const request = await fetch("https://api.rouguiz.com/v1/getData", {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const response = await request.json();
    return response;
  } catch (error) {
    throw error; // Relancer l'erreur pour qu'elle puisse être gérée ailleurs
  }
}

export async function refreshAccessToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken === "" || refreshToken === null) {
    return { error: "Token Manquant" };
  }
  const url = "https://api.rouguiz.com/v1/refresh-token";

  const request = await fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });

  const response = await request.json();
  return response;
}
