import { useEffect, useState } from "react";
import {
  filterCountry,
  filterNationality,
  filterCode,
  getAllCountry,
} from "../../../api/autoCompletAPI";
import { useDispatch, useSelector } from "react-redux";
import { updateValue } from "../../../store/features/settings";

export function ModalSelect({ editable, setIsFocused, placeholder, name }) {
  const [option, setOption] = useState([]); // Pour stocker les options de pays ou de nationalités
  const [searchTerm, setSearchTerm] = useState(""); // Valeur de l'input
  const [filteredOptions, setFilteredOptions] = useState([]); // Options filtrées en fonction de la recherche
  const id = useSelector((state) => state.modal?.element?.id);
  const isSent = useSelector((state) => state.modal?.isSent);

  // Charger les données initiales selon l'ID du modal
  useEffect(() => {
    const getdata = async () => {
      const data = await getAllCountry();
      switch (id) {
        case "contact_1":
          setOption(filterCountry(data)); // Charger les pays
          break;
        case "personal_6":
          setOption(filterNationality(data)); // Charger les nationalités
          break;
        case "contact_3":
          setOption(filterCode(data)); // Charger les codes des pays
          break;
        default:
          break;
      }
    };
    getdata();
  }, [id]);

  const dispatch = useDispatch();
  // Filtrer les options en fonction du terme de recherche dans l'input
  const handleSearchChange = (value) => {
    const term = value;
    dispatch(updateValue({ id, name, value: term }));

    setSearchTerm(term);

    if (term === "") {
      setFilteredOptions([]); // Si le champ est vide, ne pas afficher de suggestions
    } else {
      let filtered;
      switch (id) {
        case "contact_1":
          filtered = option.filter(({ country }) =>
            country.toLowerCase().includes(term.toLowerCase())
          );
          break;
        case "personal_6":
          filtered = option.filter(({ nationality }) =>
            nationality.toLowerCase().includes(term.toLowerCase())
          );
          break;
        case "contact_3":
          filtered = option.filter(({ code }) =>
            code.toLowerCase().includes(term.toLowerCase())
          );
          break;
        default:
          filtered = [];
      }
      setFilteredOptions(filtered); // Mettre à jour les options filtrées
    }
  };

  // Lorsque l'utilisateur clique sur un item, l'input prend sa valeur et la liste se vide
  const handleItemClick = (value) => {
    setSearchTerm(value); // Mettre à jour l'input avec la valeur sélectionnée
    dispatch(updateValue({ id, name, value }));
    setFilteredOptions([]); // Cacher les options
  };

  return (
    <div className="autocomplete-container">
      <input
        type="text"
        className="form-control"
        value={searchTerm}
        name={name}
        onChange={(e) => handleSearchChange(e.target.value)}
        onFocus={() => editable && setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        disabled={isSent}
      />
      {filteredOptions.length > 0 && (
        <ul className="autocomplete-list">
          {filteredOptions.map((item, index) => (
            <li
              key={index}
              className="autocomplete-item"
              onClick={() =>
                handleItemClick(
                  id === "contact_1"
                    ? item.country
                    : id === "personal_6"
                    ? item.nationality
                    : item.code
                )
              }
            >
              {id === "contact_1"
                ? item.country
                : id === "personal_6"
                ? item.nationality
                : item.code}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
