import { useDispatch, useSelector } from "react-redux";
import { CustomModal } from "../../Modal/Modal";
import { getModalFieldsConfig } from "../../../data/DataSettings";
import { useEffect, useState } from "react";
import {
  closeModal,
  setIsApmtyInput,
  setIsSent,
  setModalMessage,
} from "../../../store/features/modalSlice";
import { ModalInput } from "./ModalInput";
import { ModalPicture } from "./ModalPicture";
import { fetchUpdate, fetchUpdatePic } from "../../../api/settings";
import {
  resetValue,
  updateCountryLocal,
  updateData,
  updateDataLocal,
} from "../../../store/features/settings";
import { refreshAccessToken } from "../../../api/getAll";
import { deleteToken } from "../../../store/features/adminLoginSlice";

export function EditFields() {
  const [sending, setSending] = useState(false);
  const [file, setFile] = useState(null);
  const data = useSelector((state) => state.modal);
  const message = data?.modalMessage;
  const dispatch = useDispatch();
  const element = data.element || {};
  const id = element.id;
  const editable = element.editable;
  const inputArray = getModalFieldsConfig(id, element.label, element.value);
  const dataInput = useSelector((state) => state.settings.data);
  useEffect(() => {
    // Vérifie que tous les champs sont remplis
    const allFieldsFilled = Object?.values(dataInput)?.every(
      (value) => value && value?.trim() !== ""
    );
    dispatch(setIsApmtyInput(!allFieldsFilled));
  }, [dataInput, dispatch]);
  const handleCloseModal = () => {
    dispatch(closeModal());
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
    dispatch(setIsApmtyInput(true));
    dispatch(updateData({}));
  };
  const fetchSuccess = (id, value) => {
    if (id === "contact_1") {
      dispatch(updateCountryLocal(dataInput["country"]));
    }
    dispatch(updateDataLocal({ id, value }));
    dispatch(setIsSent(true));
    setSending(false);
    dispatch(resetValue());
    handleCloseModal();
  };
  const fetchError = () => {
    dispatch(setIsSent(false));
    dispatch(setModalMessage("Erreur lors de la soumission du formulaire !!"));
    setSending(false);
  };
  const onConfirm = () => {
    setSending(true);
    const Update = async () => {
      if (data === null || id === "") {
        dispatch(setModalMessage("Tous les champs sont requis"));

        return;
      }
      try {
        if (id === "personal_7") {
          if (file) {
            const formData = new FormData();
            formData.append("img", file);

            const response = await fetchUpdatePic(id, formData);
            const status = response.status;
            if (status === "201") {
              const value = response.value;
              fetchSuccess(id, value);
            } else if (status === "401" && response.error === "Token Expiré") {
              const fetchRefreshToken = await refreshAccessToken();
              if (fetchRefreshToken.status === "201") {
                const token = JSON.stringify(fetchRefreshToken.accessToken);
                sessionStorage.setItem("token", token);

                const response = await fetchUpdatePic(id, formData);

                const status = response.status;
                if (status === "201") {
                  const value = response.value;
                  fetchSuccess(id, value);
                } else {
                  const error = response.error;
                  fetchError();
                  dispatch(setModalMessage(error));
                }
              } else {
                fetchError();
                dispatch(deleteToken());
              }
            } else {
              const error = response.error;
              fetchError();
              dispatch(setModalMessage(error));
            }
          } else {
            dispatch(setModalMessage("Tous les champs sont requis"));
            setSending(false);
            return;
          }
          setSending(false);
          return;
        }
        const request = await fetchUpdate(dataInput, id);
        const status = request.status;
        if (status === "201") {
          const value = request.value;
          fetchSuccess(id, value);
        } else if (status === "401" && request.error === "Token Expiré") {
          // on fait un refresh token et on refait la requete
          const fetchRefreshToken = await refreshAccessToken();
          if (fetchRefreshToken.status === "201") {
            const token = JSON.stringify(fetchRefreshToken.accessToken);
            sessionStorage.setItem("token", token);

            const request = await fetchUpdate(dataInput, id);
            if (request.status === "201") {
              const value = request.value;
              fetchSuccess(id, value);
            } else {
              fetchError();
            }
          } else {
            fetchError();
            dispatch(deleteToken());
          }
        } else {
          const error = request.error;
          fetchError();
          dispatch(setModalMessage(error));
        }
      } catch {
        dispatch(
          setModalMessage(
            "Erreur lors de la soummission du formulaire, Réessayez plutard"
          )
        );
        setSending(false);
      }
    };
    setTimeout(() => Update(), 3000);
  };
  return (
    <CustomModal
      title={element.modalTitle}
      isInputEditable={element && !element.editable}
      sending={sending}
      onConfirm={() => onConfirm()}
      resetValues={() => dispatch(updateData({}))}
    >
      {id === "personal_7" ? (
        <ModalPicture setFile={setFile} />
      ) : (
        <form>
          {inputArray.map((element, index) => (
            <ModalInput
              key={`${id}-${index}`}
              name={element.name}
              label={element.label}
              id={id}
              type={element.type}
              editable={editable}
              placeholder={element.placeholder}
            />
          ))}
        </form>
      )}
      {message && (
        <p
          className={
            message === "Modification Reussie !!" ? "success" : "error"
          }
        >
          {message}
        </p>
      )}
    </CustomModal>
  );
}
