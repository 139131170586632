import { HeaderLogin } from "../components/Header/HeaderLogin";
import { LoginForm } from "../components/LoginForm/LoginForm";

export function Login(){
    return (
        <>
          <LoginForm/> 
        </>
       
    )
}