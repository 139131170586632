import { useState } from "react";
import { ModalSelect } from "./ModalSelect";
import { useDispatch, useSelector } from "react-redux";
import { updateValue } from "../../../store/features/settings";

export function ModalInput({ name, type, label, placeholder, editable, id }) {
  const [isFocused, setIsFocused] = useState(false);
  const isSent = useSelector((state) => state.modal?.isSent);

  const dispatch = useDispatch();
  const handleInputChange = (value) => {
    dispatch(updateValue({ id, name, value }));
  };
  return (
    <div className="form-group">
      {editable && !isSent ? (
        <label
          htmlFor={name}
          className={`form-label floating-label ${isFocused && "focused"}  `}
        >
          {label}
        </label>
      ) : (
        ""
      )}
      {type === "select" ? (
        <ModalSelect
          editable={editable}
          setIsFocused={setIsFocused}
          placeholder={placeholder}
          name={name}
        />
      ) : (
        <input
          type={type}
          name={name}
          id={name}
          disabled={isSent}
          placeholder={placeholder}
          className={`form-control ${
            !editable || isSent ? "readonly-input" : ""
          }`}
          onFocus={() => editable && setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      )}
    </div>
  );
}
