import { useDispatch, useSelector } from "react-redux";
import { CustomModal } from "../../Modal/Modal";
import { Education } from "./Education";
import { Skills } from "./Skills";
import { DeleteElement } from "./DeteletElement";
import {
  addOrUpdateEducation,
  addOrUpdateSkill,
  deleteEducationLocal,
  deleteSkillLocal,
  resetValue,
} from "../../../store/features/resume";
import { useEffect, useState } from "react";
import {
  closeModal,
  setIsApmtyInput,
  setIsSent,
  setModalMessage,
} from "../../../store/features/modalSlice";
import {
  fetchAddEducation,
  fetchAddSkill,
  fetchDeleteEducation,
  fetchDeleteSkill,
  fetchUpdateEducation,
  fetchUpdateSkill,
} from "../../../api/resumeSection";
import { refreshAccessToken } from "../../../api/getAll";
import { deleteToken } from "../../../store/features/adminLoginSlice";

export function ModalContent() {
  const element = useSelector((state) => state?.resume?.modalContent) || {};
  const dataInput = useSelector((state) => state.resume.dataInput);
  const [sending, setSending] = useState(false);
  const [file, setFile] = useState(null);

  const data = useSelector((state) => state.modal);
  const message = data?.modalMessage;
  const configId = element?.id,
    id = element?.cardId,
    title = element?.modalTile,
    method = element?.method,
    cardElement = element?.element;

  const dispatch = useDispatch();
  useEffect(() => {
    // Vérifie que tous les champs sont remplis
    const allFieldsFilled = Object?.values(dataInput)?.every(
      (value) => value && value?.trim() !== ""
    );
    dispatch(setIsApmtyInput(!allFieldsFilled));
  }, [dataInput, dispatch]);

  const handleCloseModal = () => {
    dispatch(resetValue());
    dispatch(closeModal());
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
    dispatch(setIsApmtyInput(true));
  };
  const fetchSuccess = (id, addedElement) => {
    const newLocaldata = addedElement ? addedElement : dataInput;

    if (cardElement === "education") {
      method === "DELETE"
        ? dispatch(deleteEducationLocal(id))
        : dispatch(addOrUpdateEducation({ id, newEducation: newLocaldata }));
    } else {
      method === "DELETE"
        ? dispatch(deleteSkillLocal(id))
        : dispatch(addOrUpdateSkill({ id, newSkill: newLocaldata }));
    }

    dispatch(setIsSent(true));

    setSending(false);
    handleCloseModal();
  };
  const fetchError = () => {
    dispatch(setIsSent(false));
    dispatch(setModalMessage("Erreur lors de la soumission du formulaire !!"));
    setSending(false);
  };
  const switchFetch = async (element, method) => {
    if (element === "education") {
      switch (method) {
        case "PUT":
          return await fetchUpdateEducation(id, dataInput);
          break;
        case "POST":
          return await fetchAddEducation(dataInput);
          break;
        case "DELETE":
          return await fetchDeleteEducation(id);
          break;
        default:
          fetchError();
      }
    } else if (element === "skill") {
      const formData = new FormData();
      formData.append("skill_img", file);
      formData.append("title", dataInput.title);
      switch (method) {
        case "UPDATE":
          return await fetchUpdateSkill(id, formData);
          break;
        case "POST":
          return await fetchAddSkill(formData);
          break;
        case "DELETE":
          return await fetchDeleteSkill(id);
          break;
        default:
          fetchError();
      }
    }
  };
  const onConfirm = () => {
    setSending(true);
    const update = async () => {
      try {
        const response = await switchFetch(cardElement, method);
        const status = response.status;
        if (status === "201") {
          fetchSuccess(
            response.element ? response.element.id : id,
            response.element
          );
        } else if (status === "401" && response.error === "Token Expiré") {
          // refresh token
          const fetchRefreshToken = await refreshAccessToken();
          if (fetchRefreshToken.status === "201") {
            const token = JSON.stringify(fetchRefreshToken.accessToken);
            sessionStorage.setItem("token", token);

            const request = await switchFetch(cardElement, method);

            if (request.status === "201") {
              fetchSuccess(
                response.element ? response.element.id : id,
                response.element
              );
            } else {
              const error = request.error;
              fetchError();
              dispatch(setModalMessage(error));
            }
          } else {
            fetchError();
            dispatch(deleteToken());
          }
        } else {
          const error = response.error;
          fetchError();
          dispatch(setModalMessage(error));
        }
        setSending(false);
      } catch (error) {
        dispatch(
          setModalMessage(
            "Erreur lors de la soummission du formulaire, Réessayez plutard"
          )
        );
        setSending(false);
      }
    };

    setTimeout(() => update(), 3000);
  };

  return (
    <CustomModal
      title={title}
      isSent={false}
      sending={sending}
      resetValues={() => dispatch(resetValue())}
      onConfirm={() => onConfirm()}
    >
      {configId === "education" || configId === "addEducation" ? (
        <Education />
      ) : configId === "addSkill" || configId === "updateSkill" ? (
        <Skills
          title={dataInput?.title}
          setFile={setFile}
          id={id}
          icon={dataInput.icon}
        />
      ) : (
        <DeleteElement />
      )}
      {message && (
        <p
          className={
            message === "Modification Reussie !!" ? "success" : "error"
          }
        >
          {message}
        </p>
      )}
    </CustomModal>
  );
}
