import { useSelector } from "react-redux";
import profil from "./../../assets/images/profil.jpg";

import { CardInfo } from "./CardInfo";
import { Picture } from "./Picture";
// *************les li de la section "Information personnelles" *************

export function AdminInfo() {
  const adminInfo = useSelector((state) => state.settings.adminProfile) || [];
  const cardInfo = adminInfo.filter((card) => card.id !== "personal_7");
  const cardPicture = adminInfo.filter((card) => card.id === "personal_7");

  return (
    <ul className="row list-inline adminInfo gap-lg-0 gap-3">
      {cardPicture.map(
        ({ id, label, value, editable, modal_title, options }, index) => (
          <li
            key={index}
            className="col-lg-5 col-12 adminInfo_item "
            onClick={() => {}}
          >
            <Picture
              value={value}
              id={id}
              label={label}
              editable={editable}
              modalTitle={modal_title}
              options={options || []}
            />
          </li>
        )
      )}

      <li className="col-lg-7 col-12 cards">
        <ul className="d-flex row card-right">
          {cardInfo.map(
            ({ id, label, value, editable, modal_title, options }) => (
              <CardInfo
                key={id}
                title={label}
                description={value}
                editable={editable}
                id={id}
                modalTitle={modal_title}
                options={options || []}
              />
            )
          )}
        </ul>
      </li>
    </ul>
  );
}
