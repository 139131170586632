import { useEffect } from "react";
import { fetchBlogData } from "../api/blogSession";
import { useDispatch, useSelector } from "react-redux";
import { setBlogData } from "../store/features/blog";
import { Card } from "../components/Blog/Card";

export function Blog() {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchBlogData();
      if (data) {
        dispatch(setBlogData(data));
      }
    };

    fetchData();
  }, []);
  const blogData = useSelector((state) => state?.blog?.blogData);
  return (
    <div className="BlogSession">
      <h2>Blogs</h2>
      <ul className="row list-inline d-flex gap-2">
        {blogData?.map(
          (
            {
              id,
              title,
              category,
              description,
              date,
              image,
              url,
              views,
              comments,
            },
            index
          ) => (
            <Card
              key={index}
              id={id}
              title={title}
              category={category}
              description={description}
              date={date}
              image={image}
              url={url}
              views={views}
              comments={comments}
            />
          )
        )}
      </ul>
    </div>
  );
}
