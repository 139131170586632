export async function filterCommentButtons() {
  const request = await fetch("./filtreComments.json");
  return request.json();
}

export async function fetchUpdateComment(id, data) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/comments/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return request.json();
}

export async function fetchDeleteComment(id) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/comments/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const response = await request.json();
  return response;
}
