export async function getAllCountry() {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all");
    const data = await response.json();

    const nationalities = data.map((country) => ({
      country: country.name.common,
      nationality: country.demonyms?.fra?.f, // Le nom de la nationalité en français (masculin)
      code:
        country.idd?.root +
        (country.idd?.suffixes && country.idd.suffixes.length === 1
          ? country.idd.suffixes[0]
          : "") +
        " (" +
        country.cca3 +
        ")",
    }));

    return nationalities;
  } catch (error) {
    console.error("Erreur lors de la récupération des données", error);
    return [];
  }
}

export function filterCountry(data) {
  const countries = data.map((element) => ({
    country: element.country,
  }));
  const filterCountries = countries.sort((a, b) =>
    a.country.localeCompare(b.country)
  );
  return filterCountries;
}
export function filterNationality(data) {
  const nationalities = data.map((element) => ({
    nationality: element?.nationality || "",
  }));
  const filter = nationalities.sort((a, b) =>
    a?.nationality.localeCompare(b?.nationality)
  );
  return filter;
}
export function filterCode(data) {
  const codes = data.map((element) => ({
    code: element?.code || "",
  }));
  const filter = codes.sort((a, b) => a?.code.localeCompare(b?.code));
  return filter;
}
