export async function fetchUpdate(data, id) {
  const url = id.includes("personal")
    ? "admin-info"
    : id.includes("contact")
    ? "admin-contact"
    : "admin-setting";
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }

  const request = await fetch(`https://api.rouguiz.com/v1/${url}/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return request.json();
}
export async function fetchUpdatePic(id, formData) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }

  const request = await fetch(`https://api.rouguiz.com/v1/admin-info/${id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });
  const response = await request.json();
  return response;
}
export async function fetchDeletePic(id) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }

  const request = await fetch(`https://api.rouguiz.com/v1/admin-info/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  return request.json();
}
