import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    homeData: [],
    modalData: {},
    dataInput: {}, // Les données saisie
  },
  reducers: {
    setHomeData: (state, actions) => {
      state.homeData = actions.payload;
    },
    setModalData: (state, actions) => {
      state.modalData = actions.payload;
    },
    //Initialiser l'objet en charges des saisie des inputs
    setDataInput: (state, actions) => {
      const { name, value } = actions.payload;

      state.dataInput[name] = value;
    },
    // Capturer et mettre à jour les valeur lors de l'evenement input
    updateInputValue: (state, actions) => {
      const { name, value } = actions.payload;
      state.dataInput[name] = value;
    },
    UpdateHome: (state, actions) => {
      const newObject = actions.payload;
      if (!state.homeData) {
        // Si `projectData` est vide, initialisez-le comme un tableau
        state.homeData = [];
      }

      // Si l'ID existe, mettez à jour l'objet
      state.homeData[0] = {
        ...state.homeData[0],
        ...newObject,
      };
    },
    //Reinitialiser les données apres la soumission du formulaire
    resetValue: (state) => {
      state.dataInput = {};
      state.modalData = {};
    },
  },
});

export const {
  setModalData,
  setHomeData,
  setDataInput,
  updateInputValue,
  UpdateHome,
  resetValue,
} = homeSlice.actions;
export default homeSlice.reducer;
