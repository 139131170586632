import { useDispatch } from "react-redux";
import { setcategoryFilter } from "../../store/features/comments";

export function FilterButton({ id, label, defaultId, setDefaultId }) {
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (defaultId === id) {
      setDefaultId("");
      dispatch(setcategoryFilter(""));
    } else {
      setDefaultId(id);
      dispatch(setcategoryFilter(id));
    }
  };
  return (
    <button
      className={`btn ${defaultId === id ? "btn-primary" : "btn-secondary"}`}
      onClick={() => {
        handleButtonClick();
      }}
    >
      {label}
    </button>
  );
}
