import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateInputValue } from "../../../store/features/home";
//  les input pour la modal education, pour eviter les conflit sur le label
export function Input({ value }) {
  const [isFocused, setIsFocused] = useState(false);
  const element = useSelector((state) => state?.home?.modalData) || {};
  const cardElement = element?.element;
  const name = cardElement === "description" ? "description" : "title";
  const dispatch = useDispatch();
  const hanleChange = (value) => {
    dispatch(updateInputValue({ name, value }));
  };
  return (
    <>
      {cardElement === "description" ? (
        <textarea
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => hanleChange(e.target.value)}
          name={name}
          id={name}
          className="form-control"
          value={value}
        ></textarea>
      ) : (
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => hanleChange(e.target.value)}
          name={name}
          type={"text"}
          value={value}
          id={name}
          className="form-control"
        />
      )}
    </>
  );
}
