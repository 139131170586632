import { useState } from "react";
import woman from "./../../../assets/images/woman.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeletePic } from "../../../api/settings";
import {
  setIsApmtyInput,
  setIsSent,
  setModalMessage,
} from "../../../store/features/modalSlice";
import { updateDataLocal, updateValue } from "../../../store/features/settings";
import { refreshAccessToken } from "../../../api/getAll";
import { deleteToken } from "../../../store/features/adminLoginSlice";

export function ModalPicture({ setFile }) {
  const [sending, setSending] = useState(false);
  const imgUrl = useSelector(
    (state) => state?.settings?.adminProfile[6]?.value
  );
  const id = useSelector((state) => state?.settings?.adminProfile[6]?.id);
  const dispatch = useDispatch();

  const url = imgUrl ? imgUrl : woman;
  const [image, setImage] = useState(url);
  const handleImageChange = (e) => {
    dispatch(setModalMessage(""));

    const file = e.target.files[0];
    if (file) {
      dispatch(updateValue({ id, name: "img", value: "success" }));

      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const fetchSuccess = (id, value) => {
    dispatch(setModalMessage("Modification Reussie !!"));
    setImage(woman);
    dispatch(updateDataLocal({ id, value }));
    setSending(false);
    dispatch(setIsSent(false));
  };
  const fetchError = () => {
    dispatch(setModalMessage("Erreur lors de la soumission du formulaire !!"));
    setSending(false);
    dispatch(setIsSent(false));
  };
  const handleDetelePic = () => {
    if (imgUrl === "" || imgUrl === null) {
      dispatch(setModalMessage("Aucune Image n'est présente"));
      return;
    }

    dispatch(setIsSent(true));
    setSending(true);

    const update = async () => {
      const response = await fetchDeletePic(id);
      const status = response.status;
      if (status === "201") {
        const value = response.value;
        fetchSuccess(id, value);
      } else if (status === "401") {
        const fetchRefreshToken = await refreshAccessToken();
        if (fetchRefreshToken.status === "201") {
          const token = JSON.stringify(fetchRefreshToken.accessToken);
          sessionStorage.setItem("token", token);

          const response = await fetchDeletePic(id);
          const status = response.status;
          if (status === "201") {
            const value = response.value;
            fetchSuccess(id, value);
          } else if (status === "401") {
            fetchError();
          } else {
            fetchError();
          }
        } else {
          dispatch(deleteToken());
        }
      }
    };

    setTimeout(() => update(), 3000);
  };
  return (
    <div className="modifyPicture">
      <span className="Picture d-flex">
        <img src={image} alt="profil" />
      </span>
      <span className="buttons d-flex">
        <button
          className={`btn ${sending && "isSending"}`}
          disabled={sending}
          onClick={() => handleDetelePic()}
        >
          {sending ? (
            <i className="fa-solid fa-spinner fa-spin "></i>
          ) : (
            <i className="fa-solid fa-trash-can"></i>
          )}
          <span>Supprimer</span>
        </button>
        <button className="btn " disabled={sending}>
          <input
            type="file"
            accept="image/*"
            className=""
            onChange={(e) => handleImageChange(e)}
          />
          <i className="fa-solid fa-camera"></i>
          <span>Ajouter une photo</span>
        </button>
      </span>
      <hr />
    </div>
  );
}
