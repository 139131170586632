import {  NavLink } from "react-router-dom"
export function LinkElement({url,icon,name}){
    return(
         <li id={name}>
            <NavLink to={url} 
                className={({isActive})=>(isActive ? 'active' : '')}>
                <i className={icon}></i>
                <span>{name}</span>
            </NavLink>
        </li>
    )
}