export async function fetchUpdateText(data) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/admin-home`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return request.json();
}
export async function fetchUpdatPic(data) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/admin-home`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: data,
  });
  const response = await request.json();
  return response;
}
