import { useDispatch } from "react-redux";
import { openModal } from "../../store/features/modalSlice";
import { setDataInput, setModalData } from "../../store/features/resume";

export function SkillsCard({ id, title, icon }) {
  const dispatch = useDispatch();
  const dataInput = {
    title,
    id,
    icon,
  };
  const element = {
    modalTile: "Mettre à jour cette compétence",
    method: "UPDATE",
    cardId: id,
    id: "updateSkill",
    element: "skill",
  };
  const handleClick = () => {
    dispatch(setModalData(element));
    dispatch(openModal());
    dispatch(setDataInput(dataInput));
  };
  const deleteElement = {
    method: "DELETE", // Pour récuperer dynamiquement la methode lors de la soumission du formulaire
    element: "skill",
    cardId: id,
  };
  const handleDeleteClick = (e) => {
    dispatch(setModalData(deleteElement));

    dispatch(openModal());
    // dispatch(setModalData(null));
    e.stopPropagation();
  };
  return (
    <li className="card col-md-3 col-5" onClick={() => handleClick()}>
      <span className="icon" onClick={(e) => handleDeleteClick(e)}>
        <i className="fa-solid fa-trash-can"></i>
      </span>
      <img src={icon} alt={`${title}- svg`} />
      <h4>{title}</h4>
    </li>
  );
}
