// import { accountSettings } from "../../data/DataSettings";
import { useSelector } from "react-redux";
import { CardInfo } from "./CardInfo";
import { accountSettings } from "../../data/DataSettings";

// *************les li de la section "Parametres du compte" *************
export function AdminAccompte() {
  const accountSettingLocal =
    useSelector((state) => state.settings.adminSetting) ||
    JSON.parse(localStorage.getItem("admin_settings")) ||
    [];
  return (
    <ul className="row list-inline adminAddress ">
      <li className="col-lg-7 col-12 cards ">
        <ul className="d-flex row card-right">
          {accountSettingLocal.map(
            ({ id, label, value, editable, modal_title, options }) => (
              <CardInfo
                key={id}
                title={label}
                description={value}
                editable={editable}
                id={id}
                modalTitle={modal_title}
                options={options || []}
              />
            )
          )}
        </ul>
      </li>
    </ul>
  );
}
