export function User({ name, userEmail }) {
  return (
    <div className="user d-flex gap-2">
      <span className="icon">
        <i className="fa-solid fa-user"></i>
      </span>
      <div className="d-flex">
        <h3>{name}</h3>
        <span className="email">{userEmail}</span>
      </div>
    </div>
  );
}
