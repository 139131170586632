import { CardEducation } from "../components/Resume/CardEducation";
import { SkillsCard } from "../components/Resume/SkillsCard";
import { ModalContent } from "../components/Resume/ModalContent/ModalContent";
import { Buttons } from "../components/Resume/Buttons";
import { useSelector } from "react-redux";

export function Resume() {
  const data = useSelector((state) => state?.resume);
  const education = data?.education || [];

  const skills = data?.skills || [];
  return (
    <>
      <div className="ResumeSection">
        <h2>Resume</h2>
        <Buttons />

        <h3 className="d-flex gap-2">
          <span>
            <i className="fa-solid fa-graduation-cap"></i>
          </span>
          Education
        </h3>
        <ul className="row list-inline  cards-top  ">
          {education?.map(
            (
              { id, title, description, schoolName, schoolLink, year },
              index
            ) => (
              <CardEducation
                key={index}
                id={id}
                title={title}
                description={description}
                schoolName={schoolName}
                schoolLink={schoolLink}
                year={year}
              />
            )
          )}
        </ul>
        <h3 className="d-flex gap-2">
          <span>
            <i className="fa-solid fa-code"></i>
          </span>
          Compétences
        </h3>
        <ul className="row list-inline skills">
          {skills?.map(({ id, title, icon }, index) => (
            <SkillsCard id={id} title={title} icon={icon} key={index} />
          ))}
        </ul>
      </div>
      <ModalContent />
    </>
  );
}
