import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./style.css";
ChartJS.register(ArcElement, Tooltip, Legend);

const LoadChart = () => {
  const data = {
    labels: ["Optimisation SEO", "Méthodologie Agile", "API-restful"],
    datasets: [
      {
        data: [35, 60, 5], // Données en pourcentage
        backgroundColor: ["#7E57C2", "#4FC3F7", "#66BB6A"],
        borderWidth: 0, // Supprime les bordures blanches
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Désactive la légende par défaut
      },
    },
  };

  return (
    <div className="graphique">
      <div className="circle">
        <Doughnut data={data} options={options} />
      </div>
      <div className="container">
        {data.labels.map((label, index) => (
          <div className="chart" key={index}>
            <span
              style={{
                width: "10px",
                height: "9px",
                backgroundColor: data.datasets[0].backgroundColor[index],
                borderRadius: "50%",
                display: "inline-block",
                marginRight: "10px",
              }}
            ></span>
            <span className="data">
              {label} :{" "}
              <span className="pourcent">{data.datasets[0].data[index]}%</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadChart;
