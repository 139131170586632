import { useDispatch } from "react-redux";
import { openModal, setElement } from "../../store/features/modalSlice";
import { updateData } from "../../store/features/settings";
import { calculateAge, dataFormConfig } from "../../data/DataSettings";

export function CardInfo({
  title,
  description,
  editable,
  id,
  modalTitle,
  options,
}) {
  const element = {
    id,
    label: title,
    value: description,
    editable,
    modalTitle,
    options,
  };
  const dataformInputConfig = dataFormConfig(id);

  const dispatch = useDispatch();
  const setData = () => {
    dispatch(updateData(dataformInputConfig));

    dispatch(setElement(element));
    dispatch(openModal());
  };

  return (
    <>
      <li className="cardInfo d-flex  col-12" onClick={() => setData()}>
        <h4> {title} </h4>
        <p>
          {id === "personal_3"
            ? calculateAge(description) + " ans"
            : description}
        </p>

        <span>
          <i className={`fa-solid fa-chevron-right`}> </i>
        </span>
      </li>
    </>
  );
}
