import { useDispatch } from "react-redux";
import { setDataInput, setModalData } from "../../store/features/resume";
import { openModal } from "../../store/features/modalSlice";

export function CardEducation({
  id,
  title,
  description,
  schoolName,
  schoolLink,
  year,
}) {
  //Pour la configuration
  const element = {
    modalTile: "Details sur le diplôme",
    element: "education",
    method: "PUT", // Pour récuperer dynamiquement la methode lors de la soumission du formulaire
    id: "education",
    cardId: id,
    title,
    year,
    description,
    schoolLink,
    schoolName,
  };
  // Pour la identifier la requete
  const deleteElement = {
    method: "DELETE", // Pour récuperer dynamiquement la methode lors de la soumission du formulaire
    element: "education",
    cardId: id,
  };

  // Pour les données de l'input
  const dataInput = {
    title,
    year,
    description,
    schoolLink,
    schoolName,
  };
  const dispatch = useDispatch();
  const handleDeleteClick = (e) => {
    dispatch(setModalData(deleteElement));
    dispatch(openModal());
    // dispatch(setModalData(null));

    e.stopPropagation();
  };
  const handleClick = () => {
    dispatch(setModalData(element));
    dispatch(openModal());
    dispatch(setDataInput(dataInput));
  };
  return (
    <li
      className="col-lg-6 col-12 card py-3 px-2"
      onClick={() => handleClick()}
    >
      <span className="icon" onClick={(e) => handleDeleteClick(e)}>
        <i className="fa-solid fa-trash-can"></i>
      </span>
      <h4>
        {title}
        <span>{year}</span>
      </h4>
      <p>{description}</p>
      <span>
        <a href={schoolLink}>{schoolName}</a>
      </span>
    </li>
  );
}
