// import React from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   LinearScale,
//   CategoryScale,
//   Tooltip,
//   Filler,
// } from "chart.js";

// ChartJS.register(
//   LineElement,
//   PointElement,
//   LinearScale,
//   CategoryScale,
//   Tooltip,
//   Filler
// );

// const ViewsChart = () => {
//   const data = {
//     labels: ["Avril", "Mai", "Juin", "Juillet", "Août", "Sept."],
//     datasets: [
//       {
//         label: "Vues du portfolio",
//         data: [100, 200, 300, 400, 350, 370],
//         borderColor: "#4FC3F7", // Ligne bleu clair
//         backgroundColor: (context) => {
//           const chart = context.chart;
//           const { ctx, chartArea } = chart;
//           if (!chartArea) {
//             return null; // Retourne null si la zone du graphique n'est pas encore disponible
//           }
//           const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
//           gradient.addColorStop(0, "rgba(79, 195, 247, 0.5)"); // Couleur dégradée en haut
//           gradient.addColorStop(1, "rgba(79, 195, 247, 0)");   // Transparent en bas
//           return gradient;
//         },
//         fill: "origin", // Remplissage sous la courbe
//         tension: 0.4, // Courbure de la ligne
//         pointRadius: 4,
//         pointBackgroundColor: "#FFFFFF",
//         pointBorderWidth: 2,
//         pointBorderColor: "#4FC3F7",
//       },
//       {
//         label: "Pointillés",
//         data: [null, null, null, null, null, 370], // Ligne uniquement à la fin
//         borderColor: "#4FC3F7",
//         borderDash: [5, 5], // Pointillés
//         borderWidth: 1.5,
//         tension: 0, // Pas de courbure
//         pointRadius: 0, // Pas de points
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         ticks: {
//           color: "#E0E0E0",
//           font: { size: 12 },
//         },
//         grid: {
//           display: false,
//         },
//       },
//       y: {
//         ticks: {
//           color: "#E0E0E0",
//           font: { size: 12 },
//         },
//         grid: {
//           borderDash: [4, 4],
//           color: "rgba(255, 255, 255, 0.1)",
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         display: false,
//       },
//       tooltip: {
//         backgroundColor: "#333",
//         titleColor: "#FFF",
//         bodyColor: "#FFF",
//         callbacks: {
//           label: function (context) {
//             return `${context.raw} vues`;
//           },
//         },
//       },
//     },
//   };

//   return (
//     <div style={{ height: "220px" }}>
//       <h4 style={{ color: "#4FC3F7", textAlign: "center", fontSize: "16px" }}>
//         Vues du Portfolio (6 derniers mois)
//       </h4>
//       <div style={{ height: "200px" }}>
//         <Line data={data} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ViewsChart;

import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler
);

// Plugin pour la ligne verticale pointillée
const verticalLinePlugin = {
  id: "verticalLine",
  beforeDraw: (chart) => {
    const { ctx, chartArea, scales } = chart;

    // Vérification que les échelles sont disponibles
    if (!chartArea || !scales.x || !scales.y) {
      return;
    }

    const { top, bottom } = chartArea;
    const { x, y } = scales;

    // Récupérer la dernière valeur (dernier point)
    const lastPointIndex = chart.data.datasets[0].data.length - 1;
    const xPos = x.getPixelForValue(lastPointIndex);
    const yPos = y.getPixelForValue(chart.data.datasets[0].data[lastPointIndex]);

    // Dessiner la ligne pointillée
    ctx.save();
    ctx.setLineDash([5, 5]); // Définir les pointillés
    ctx.strokeStyle = "#FFFFFF"; // Couleur des pointillés
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(xPos, yPos); // Point de départ (dernier point de la courbe)
    ctx.lineTo(xPos, bottom); // Descendre verticalement jusqu'au bas
    ctx.stroke();
    ctx.restore();
  },
};

ChartJS.register(verticalLinePlugin);

const ViewsChart = () => {
  const data = {
    labels: ["Avril", "Mai", "Juin", "Juillet", "Août", "Sept."],
    datasets: [
      {
        label: "Vues du portfolio",
        data: [100, 200, 300, 400, 350, 370],
        borderColor: "#4FC3F7", // Ligne bleu clair
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, "rgba(79, 195, 247, 0.5)");
          gradient.addColorStop(1, "rgba(79, 195, 247, 0)");
          return gradient;
        },
        fill: "origin", // Remplissage sous la courbe
        tension: 0.4, // Courbure de la ligne
        pointRadius: 4,
        pointBackgroundColor: "#FFFFFF",
        pointBorderWidth: 2,
        pointBorderColor: "#4FC3F7",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: "#E0E0E0",
          font: { size: 12 },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "#E0E0E0",
          font: { size: 12 },
        },
        grid: {
          borderDash: [4, 4],
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#333",
        titleColor: "#FFF",
        bodyColor: "#FFF",
        callbacks: {
          label: function (context) {
            return `${context.raw} vues`;
          },
        },
      },
    },
  };

  return (
    <div style={{ height: "220px" }}>
      <h4 style={{ color: "#4FC3F7", textAlign: "center", fontSize: "16px" }}>
        Vues du Portfolio (6 derniers mois)
      </h4>
      <div style={{ height: "200px" }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default ViewsChart;
