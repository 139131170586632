import { useDispatch, useSelector } from "react-redux";
import { CustomModal } from "../../Modal/Modal";
import { useState } from "react";
import {
  deleteCommentLocal,
  resetValue,
  UpdateComment,
} from "../../../store/features/comments";
import {
  closeModal,
  setIsApmtyInput,
  setIsSent,
  setModalMessage,
} from "../../../store/features/modalSlice";
import {
  fetchDeleteComment,
  fetchUpdateComment,
} from "../../../api/commentSection";
import { refreshAccessToken } from "../../../api/getAll";
import { deleteToken } from "../../../store/features/adminLoginSlice";

export function ModalContent() {
  const dataInput = useSelector((state) => state.comment.dataInput);
  const method = dataInput.function,
    id = dataInput.id;
  const [sending, setSending] = useState(false);
  const modalFunction = useSelector((state) => state?.comment?.modalFunction);
  const data = useSelector((state) => state.modal);
  const message = data?.modalMessage;
  const dispatch = useDispatch();
  const fetchSuccess = (id) => {
    method === "DELETE"
      ? dispatch(deleteCommentLocal(id))
      : dispatch(
          UpdateComment({
            id,
            updateComment: { confirmed: dataInput.confirmed },
          })
        );
    handleCloseModal();
  };
  const switchFetch = (id, method, dataInput) => {
    return method === "DELETE"
      ? fetchDeleteComment(id)
      : fetchUpdateComment(id, dataInput);
  };
  const handleCloseModal = () => {
    dispatch(resetValue());
    dispatch(closeModal());
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
    dispatch(setIsApmtyInput(true));
  };
  const fetchError = () => {
    dispatch(setIsSent(false));
    dispatch(setModalMessage("Erreur lors de la soumission du formulaire !!"));
    setSending(false);
  };
  const onConfirm = () => {
    setSending(true);
    const update = async () => {
      try {
        const response = await switchFetch(id, method, dataInput);
        const status = response.status;
        if (status === "201") {
          fetchSuccess(id);
        } else if (status === "401" && response.error === "Token Expiré") {
          // refresh token
          const fetchRefreshToken = await refreshAccessToken();
          if (fetchRefreshToken.status === "201") {
            const token = JSON.stringify(fetchRefreshToken.accessToken);
            sessionStorage.setItem("token", token);

            const request = await switchFetch(id, method, dataInput);

            if (request.status === "201") {
              fetchSuccess(id);
            } else {
              const error = request.error;
              fetchError();
              dispatch(setModalMessage(error));
            }
          } else {
            fetchError();
            dispatch(deleteToken());
          }
        } else {
          const error = response.error;
          fetchError();
          dispatch(setModalMessage(error));
        }
        setSending(false);
      } catch (error) {
        dispatch(
          setModalMessage(
            "Erreur lors de la soummission du formulaire, Réessayez plutard"
          )
        );
        setSending(false);
      }
    };

    setTimeout(() => update(), 3000);
  };
  return (
    <CustomModal
      title={""}
      isSent={false}
      resetValues={() => dispatch(resetValue())}
      sending={sending}
      onConfirm={() => onConfirm()}
    >
      <p className="modalFunction">
        {modalFunction === "confirmeComment"
          ? "Voulez vous valider ce avis?"
          : modalFunction === "invalideComment"
          ? "Voulez vous invalider cet avis ?"
          : " Voulez vous vraiment supprimer cet avis?"}
      </p>
      {message && (
        <p
          className={
            message === "Modification Reussie !!" ? "success" : "error"
          }
        >
          {message}
        </p>
      )}
    </CustomModal>
  );
}
