import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    adminProfile: [],
    adminContact: [],
    adminSetting: [],
    data: {}, // Les données saisie
  },
  reducers: {
    addAdminProfile: (state, actions) => {
      state.adminProfile = actions.payload;
    },
    addAdminContact: (state, actions) => {
      state.adminContact = actions.payload;
    },
    addAdminSetting: (state, actions) => {
      state.adminSetting = actions.payload;
    },
    // Mise à jour des champs unique après la requête, comme nationnalité,pseudo etc
    updateDataLocal: (state, actions) => {
      const { id, value } = actions.payload;
      const item = id.includes("personal")
        ? state.adminProfile.find((item) => item.id === id)
        : id.includes("contact")
        ? state.adminContact.find((item) => item.id === id)
        : state.adminSetting.find((item) => item.id === id);
      if (item) {
        item["value"] = value;
      }
    },
    updateCountryLocal: (state, actions) => {
      state.adminContact[1].value = actions.payload;
    },
    updateData: (state, actions) => {
      state.data = actions.payload;
    },
    // Capturer et mettre à jour les valeur lors de l'evenement input
    updateValue: (state, actions) => {
      const { id, name, value } = actions.payload;
      const exceptionInputArray = [
        "contact_1",
        "contact_3",
        "account_3",
        "account_2",
      ];
      const key = exceptionInputArray.includes(id) ? name : "value";
      state.data[key] = value;
    },
    //Reinitialiser les données apres la soumission du formulaire
    resetValue: (state) => {
      state.data = {};
    },
  },
});

export const {
  addAdminContact,
  addAdminProfile,
  addAdminSetting,
  updateValue,
  updateData,
  updateCountryLocal,
  updateDataLocal,
  resetValue,
} = settingsSlice.actions;
export default settingsSlice.reducer;
