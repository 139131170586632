import woman from "./../../assets/images/woman.jpg";
import { useDispatch } from "react-redux";
import { openModal, setElement } from "../../store/features/modalSlice";
import { dataFormConfig } from "../../data/DataSettings";
import { updateData } from "../../store/features/settings";

export function Picture({ id, label, value, editable, modalTitle, options }) {
  const imgUrl = value ? value : woman;
  const element = {
    id,
    label,
    value,
    editable,
    modalTitle,
    options,
  };
  const dataformInputConfig = dataFormConfig(id);
  const dispatch = useDispatch();
  const setData = () => {
    dispatch(updateData(dataformInputConfig));
    dispatch(setElement(element));
    dispatch(openModal());
  };
  return (
    <div className="adminInfo_picture">
      <img src={imgUrl} alt="profil" />
      <span className="adminInfo_picture_icon d-flex" onClick={() => setData()}>
        <i className="fa-solid fa-pen"></i>
      </span>
    </div>
  );
}
