import { useDispatch } from "react-redux";
import { setDataInput, setModalData } from "../../store/features/resume";
import { openModal } from "../../store/features/modalSlice";

export function Buttons() {
  const elementSkills = {
    modalTile: "Ajouter une compétence",
    id: "addSkill",
    method: "POST",
    element: "skill",
  };
  const dataInputSkills = {
    title: "",
    icon: "",
  };
  const elementEducation = {
    modalTile: "Ajouter une compétence",
    id: "addEducation",
    method: "POST",
    element: "education",
    title: "",
    year: "",
    description: "",
    schoolLink: "",
    schoolName: "",
  };
  const dataInput = {
    title: "",
    year: "",
    description: "",
    schoolLink: "",
    schoolName: "",
  };
  const dispatch = useDispatch();

  const addSkills = () => {
    dispatch(setModalData(elementSkills));
    dispatch(openModal());
    dispatch(setDataInput(dataInputSkills));
  };
  const addFormation = () => {
    dispatch(setModalData(elementEducation));
    dispatch(openModal());
    dispatch(setDataInput(dataInput));
  };

  return (
    <div className="d-flex boutons">
      <button className="btn btn-secondary" onClick={() => addFormation()}>
        Ajouter une formation
      </button>
      <button className="btn btn-secondary" onClick={() => addSkills()}>
        Ajouter une compétance
      </button>
    </div>
  );
}
