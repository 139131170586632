export function HeaderLogin(){

   return(
    <header className="headerLogin">
      <h1>
        ROUGUIZ
        <span>Dévéloppeuse Web</span>
      </h1>
    </header>

   )
}