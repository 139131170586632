import { useDispatch } from "react-redux";
import { setconfirmationFilter } from "../../store/features/comments";

export function ButtonConfirmedFilter({
  id,
  isConfirmed,
  setIsConfirmed,
  label,
}) {
  const dispatch = useDispatch();

  const handleClickButton = () => {
    setIsConfirmed(id);
    dispatch(setconfirmationFilter(id));
  };
  return (
    <div className="d-flex boutons gap-3">
      <button
        className={`btn ${
          isConfirmed === id ? "btn-primary" : "btn-secondary"
        }`}
        onClick={() => {
          handleClickButton();
        }}
      >
        {label}
      </button>
    </div>
  );
}
