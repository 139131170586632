import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateInputValue } from "../../../store/features/home";

export function Picture({ image, setFile }) {
  const [uploadedFile, setUploadedFile] = useState(image); // Gère les fichiers
  const [isPdf, setIsPdf] = useState(false); // Vérifie si le fichier est un PDF
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const fileURL = URL.createObjectURL(file);
      setUploadedFile(fileURL);

      // Vérifie si le fichier est un PDF
      setIsPdf(file.type === "application/pdf");

      dispatch(updateInputValue({ name: "picture", value: "success" }));
    }
  };

  return (
    <div className="ModalSkills">
      {!uploadedFile ? (
        <div className="picture d-flex">
          <span className="icon">
            <i className="fa-solid fa-file"></i>
          </span>
          <span className="bouton">
            <input
              type="file"
              name="file"
              id="file"
              accept="image/*,application/pdf" // Accepte les images et les PDF
              onChange={handleFileChange}
            />
            <button> + Ajouter un fichier</button>
          </span>
          <span className="extensions">
            {" "}
            jpg, png, webp, svg, pdf : 10mo max
          </span>
        </div>
      ) : (
        <div className="svg-preview file-preview">
          <span
            className="icon"
            onClick={() => {
              setUploadedFile(null);
              setIsPdf(false);
              dispatch(updateInputValue({ name: "picture", value: "" }));
            }}
          >
            <i className="fa-solid fa-trash-can"></i>
          </span>

          {/* Affiche une image ou un aperçu PDF */}
          {isPdf ? (
            <iframe
              src={uploadedFile}
              className="pdf-preview"
              title="PDF Preview"
              style={{
                width: "100%",
                height: "250px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            ></iframe>
          ) : (
            <img
              src={uploadedFile}
              className="img-project"
              alt="Preview"
              style={{}}
            />
          )}
        </div>
      )}
    </div>
  );
}
