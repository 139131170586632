import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectData: [],
    modalData: {},
    dataInput: {}, // Les données saisie
  },
  reducers: {
    setProjectData: (state, actions) => {
      state.projectData = actions.payload;
    },
    setModalData: (state, actions) => {
      state.modalData = actions.payload;
    },
    //Initialiser l'objet en charges des saisie des inputs
    setDataInput: (state, actions) => {
      state.dataInput = actions.payload;
    },
    // Capturer et mettre à jour les valeur lors de l'evenement input
    updateInputValue: (state, actions) => {
      const { name, value } = actions.payload;
      state.dataInput[name] = value;
    },
    addOrUpdateProject: (state, actions) => {
      const { id, newProject } = actions.payload;
      if (!state.projectData) {
        // Si `projectData` est vide, initialisez-le comme un tableau
        state.projectData = [];
      }

      // Rechercher l'index de l'éducation avec le même ID
      const index = state.projectData.findIndex((edu) => edu.id === id);
      if (index !== -1) {
        // Si l'ID existe, mettez à jour l'objet
        state.projectData[index] = {
          ...state.projectData[index],
          ...newProject,
        };
      } else {
        // Si l'ID n'existe pas, ajoutez le nouvel objet
        state.projectData.push(newProject);
      }
    },
    deleteProjectLocal: (state, actions) => {
      const id = actions.payload;
      state.projectData = state.projectData.filter((elem) => elem.id !== id);
    },
    //Reinitialiser les données apres la soumission du formulaire
    resetValue: (state) => {
      state.dataInput = {};
      state.modalData = {};
    },
  },
});

export const {
  setModalData,
  setProjectData,
  setDataInput,
  updateInputValue,
  addOrUpdateProject,
  deleteProjectLocal,
  resetValue,
} = projectSlice.actions;
export default projectSlice.reducer;
