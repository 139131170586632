import { links } from "../../data/links";
import { LinkElement } from "./LinkElement";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteToken } from "../../store/features/adminLoginSlice.js";

export function NavigationMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMenuOpened = useSelector((state) => state?.menu?.menuOpened);
  const logOut = () => {
    dispatch(deleteToken());
    navigate("/login");
  };
  return (
    <ul
      className={`list-inline d-flex parent  ${
        isMenuOpened ? "isOpened" : "isClosed"
      }`}
    >
      {links.map(({ url, icon, name }, index) => (
        <LinkElement key={index} url={url} name={name} icon={icon} />
      ))}
      <li
        id="logout"
        onClick={() => {
          logOut();
        }}
      >
        <i className="fa-solid fa-power-off"></i>
        <span>LogOut</span>
      </li>
    </ul>
  );
}
