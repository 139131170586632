import { Heading } from "../components/Heading/Heading";
import { CustomModal } from "../components/Modal/Modal";
import { AdminAccompte } from "../components/Settings/AdminAccompte";
import { AdminAddress } from "../components/Settings/AdminAddress";
import { AdminInfo } from "../components/Settings/AdminInfo";
import { AdminSocialLink } from "../components/Settings/AdminSocialLink";
import { CardHeading } from "../components/Settings/CardHeading";
import { EditFields } from "../components/Settings/ModalContent/EditFields";

export function Settings() {
  return (
    <>
      <div className="settingsSection d-flex">
        <Heading title={"Informations du compte"} />
        <div className="settingsSection_userData cards">
          <CardHeading title={"Données personnelles"} icon={"address-card"} />
          <AdminInfo />
        </div>
        <div className="settingsSection_userData cards">
          <CardHeading title={"Addresse et contact"} icon={"location-arrow"} />
          <AdminAddress />
        </div>
        <div className="settingsSection_userData cards">
          <CardHeading title={"Paramètres du compte"} icon={"user"} />
          <AdminAccompte />
        </div>
        <div className="settingsSection_userData cards">
          <CardHeading title={"Connexion externe"} icon={"link"} />
          <AdminSocialLink />
        </div>
      </div>

      <EditFields />
    </>
  );
}
