import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function Card() {
  const data = useSelector((state) => state.comment.dataComment) || [];
  const newCommment = data.filter((comment) => comment.confirmed === false);
  const comments = data.length;
  const number = newCommment.length;
  return (
    <>
      {" "}
      <li className="card p-2 comment">
        <h3>
          Comments :<span className="badge bg-dark">{comments}</span>
        </h3>
        <p>
          To confirm :{" "}
          <span className={`badge ${number > 0 ? "bg-danger" : ""}`}>
            {number}
          </span>
        </p>

        <p>
          <Link to="/comments">
            <i className={"fa-solid fa-comment-dots"}></i>
            {"View all"}
          </Link>
        </p>
      </li>
    </>
  );
}
