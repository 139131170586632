export async function fetchUpdateEducation(id, data) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/education/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return request.json();
}
export async function fetchAddEducation(data) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/education`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const response = await request.json();
  return response;
}
export async function fetchDeleteEducation(id) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/education/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const response = await request.json();
  return response;
}
export async function fetchUpdateSkill(id, data) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/skills/${id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: data,
  });

  return request.json();
}
export async function fetchAddSkill(data) {
  
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/skills`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: data,
  });
  const response = await request.json();
  return response;
}
export async function fetchDeleteSkill(id) {
  const accessToken = JSON.parse(sessionStorage.getItem("token"));

  if (!accessToken) {
    return { error: "missing token" };
  }
  const request = await fetch(`https://api.rouguiz.com/v1/skills/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const response = await request.json();
  return response;
}
