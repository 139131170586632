import { createSlice } from "@reduxjs/toolkit";

const commentSlice = createSlice({
  name: "comment",
  initialState: {
    dataComment: [],
    confirmationFilter: "all",
    categoryFilter: "",
    filteredComment: [],
    dataButton: null,
    modalFunction: null,
    dataInput: {},
  },
  reducers: {
    setDataComment: (state, actions) => {
      state.dataComment = actions.payload;
    },
    setfilteredComment: (state, actions) => {
      state.filteredComment = actions.payload;
    },
    //Initialiser l'objet en charges des saisie des inputs
    setDataInput: (state, actions) => {
      state.dataInput = actions.payload;
    },
    setModalFunction: (state, actions) => {
      state.modalFunction = actions.payload;
    },
    setDataButton: (state, actions) => {
      state.dataButton = actions.payload;
    },
    setconfirmationFilter: (state, actions) => {
      state.confirmationFilter = actions.payload;
    },
    setcategoryFilter: (state, actions) => {
      state.categoryFilter = actions.payload;
    },
    UpdateComment: (state, actions) => {
      const { id, updateComment } = actions.payload;
      if (!state.dataComment) {
        // Si `projectData` est vide, initialisez-le comme un tableau
        state.dataComment = [];
      }

      // Rechercher l'index de l'éducation avec le même ID
      const dataIndex = state.dataComment.findIndex((edu) => edu.id === id);
      const filteredIndex = state.filteredComment.findIndex(
        (edu) => edu.id === id
      );
      if (dataIndex !== -1) {
        // Si l'ID existe, mettez à jour l'objet
        state.dataComment[dataIndex] = {
          ...state.dataComment[dataIndex],
          ...updateComment,
        };

        state.filteredComment[filteredIndex] = {
          ...state.filteredComment[filteredIndex],
          ...updateComment,
        };
        if (state.confirmationFilter !== "all") {
          state.filteredComment = state.filteredComment.filter(
            (elem) => elem.id !== id
          );
        }
      }
    },
    deleteCommentLocal: (state, actions) => {
      const id = actions.payload;
      state.dataComment = state.dataComment.filter((elem) => elem.id !== id);
      state.filteredComment = state.filteredComment.filter(
        (elem) => elem.id !== id
      );
    },
    //Reinitialiser les données apres la soumission du formulaire
    resetValue: (state) => {
      state.dataInput = {};
    },
  },
});
export const {
  setDataButton,
  setfilteredComment,
  setDataComment,
  setcategoryFilter,
  setconfirmationFilter,
  setModalFunction,
  deleteCommentLocal,
  UpdateComment,
  resetValue,
  setDataInput,
} = commentSlice.actions;
export default commentSlice.reducer;
