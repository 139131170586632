import { useDispatch, useSelector } from "react-redux";
import { Buttons } from "../components/Comments/Buttons";
import { CommentCard } from "../components/Comments/Card";
import { useEffect } from "react";
import { setfilteredComment } from "../store/features/comments";
import { ModalContent } from "../components/Comments/Modal/ModalContent";

export function Comments() {
  const data = useSelector((state) => state.comment.dataComment) || [];
  console.log(data);
  const dispatch = useDispatch();

  // Récupérer les filtres depuis Redux
  const category = useSelector((state) => state.comment.categoryFilter);
  const isConfirmed = useSelector((state) => state.comment.confirmationFilter);
  // Charger les données initiales depuis l'API

  // Filtrer les données localement
  useEffect(() => {
    const filteredData = data.filter((comment) => {
      const isCategoryMatch =
        category === "" || comment.article_id === category;

      const isConfirmationMatch =
        isConfirmed === "all" ||
        (isConfirmed === "confirmed" && comment.confirmed) ||
        (isConfirmed === "notConfirmed" && !comment.confirmed);

      return isCategoryMatch && isConfirmationMatch;
    });
    // Mettre à jour Redux uniquement lorsque les données filtrées changent
    dispatch(setfilteredComment(filteredData));
  }, [category, isConfirmed, data, dispatch]);

  // Récupérer les données filtrées depuis Redux
  const dataFiltered =
    useSelector((state) => state.comment.filteredComment) || [];

  return (
    <>
      <div className="CommentsSection d-flex">
        <h2>Commentaires</h2>

        {/* Composant pour les boutons */}
        <Buttons />

        {/* Liste des commentaires */}
        <ul className="row cards gap-2">
          {dataFiltered.map(
            (
              {
                article_id,
                id,
                articleTitle,
                name,
                email,
                comment,
                stars,
                created_at,
                confirmed,
              },
              index
            ) => (
              <CommentCard
                id={id}
                key={index}
                name={name}
                date={created_at}
                content={comment}
                articleTitle={articleTitle}
                stars={stars}
                userEmail={email}
                confirmed={confirmed}
              />
            )
          )}
        </ul>
      </div>
      <ModalContent />
    </>
  );
}
