export function Card({
  id,
  title,
  category,
  description,
  date,
  image,
  url,
  views,
  comments,
}) {
  const element = {
    id,
    title,
    category,
    description,
    date,
    image,
    url,
    views,
    comments,
  };
  return (
    <li className="card col-lg-3 col-md-4 col-12">
      <a href={url}>
        <div className="card-picture">
          <img src={image} alt={`${title}`} />
        </div>
        <div className="card-body">
          <h3>{title}</h3>
          <h4>{category}</h4>
          <p>{description}</p>
          <p>{date}</p>
          <p>
            Views :<span> {views}</span>
          </p>
          <p>
            Comments : <span>{comments}</span>{" "}
          </p>
        </div>
      </a>
    </li>
  );
}
