import { createSlice } from "@reduxjs/toolkit";
export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isOpen: false,
    isSent: false,
    isEmptyInput: true,
    element: null,
    modalMessage: "",
  },
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    setIsSent: (state, actions) => {
      state.isSent = actions.payload;
    },
    setIsApmtyInput: (state, actions) => {
      state.isEmptyInput = actions.payload;
    },
    setElement: (state, actions) => {
      state.element = actions.payload;
    },
    setOptions: (state, actions) => {
      state.element.options = actions.payload;
    },
    setModalMessage: (state, actions) => {
      state.modalMessage = actions.payload;
    },
  },
});

// Export des actions générées
export const {
  openModal,
  closeModal,
  setIsSent,
  setModalMessage,
  setIsApmtyInput,
  setElement,
  setOptions,
} = modalSlice.actions;

// Export du reducer pour le store

export default modalSlice.reducer;
