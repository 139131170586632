export function Stars({ stars }) {
  const maxRating = 5; // Nombre maximum d'étoiles

  return (
    <span>
      {[...Array(maxRating)].map((_, index) => (
        <i
          key={index}
          className={index < stars ? "fa-solid fa-star" : "fa-regular fa-star"}
        ></i>
      ))}
    </span>
  );
}
