import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateInputValue } from "../../../store/features/project";

export function Picture({ image, setFile }) {
  const [uploadedFile, setUploadedFile] = useState(image);
  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      dispatch(updateInputValue({ name: "image", value: "success" }));

      const fileURL = URL.createObjectURL(file);
      setUploadedFile(fileURL);
    }
  };

  return (
    <div className="ModalSkills">
      {!uploadedFile ? (
        <div className="picture  d-flex">
          <span className="icon">
            <i className="fa-solid fa-image"></i>
          </span>
          <span className="bouton">
            <input
              type="file"
              name="file"
              id="file"
              onChange={handleFileChange}
            />
            <button> + Ajouter photo</button>
          </span>
          <span className="extensions"> jpg, png, webp, svg : 10mo max</span>
        </div>
      ) : (
        <div className="svg-preview">
          <span
            className="icon"
            onClick={() => {
              setUploadedFile(null);
              dispatch(updateInputValue({ name: "image", value: "" }));
            }}
          >
            <i className="fa-solid fa-trash-can"></i>
          </span>
          <img
            src={uploadedFile}
            className="img-project"
            alt="Preview"
            style={{}}
          />
        </div>
      )}
    </div>
  );
}
