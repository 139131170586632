import { useDispatch, useSelector } from "react-redux";
import { setDataInput, setModalData } from "../../store/features/home";
import { openModal } from "../../store/features/modalSlice";

function Card({ title, description, value, modalTitle, elementDom, key }) {
  const dispatch = useDispatch();
  const element = {
    function: "text",
    modalTitle,
    element: elementDom,
    method: "PUT", // Pour récuperer dynamiquement la methode lors de la soumission du formulaire
    value,
  };
  const handleClick = () => {
    dispatch(setModalData(element));
    dispatch(openModal());
    dispatch(setDataInput({ name: elementDom, value }));
  };
  return (
    <article className="card">
      <span className="card-icon" onClick={() => handleClick()}>
        <i className={`fa-solid fa-pen-to-square`}></i>
      </span>
      <h4>{title}</h4>
      <p>{description}</p>
    </article>
  );
}

export function CardList() {
  const title = useSelector((state) => state.home?.homeData[0]?.title);
  const description = useSelector(
    (state) => state.home?.homeData[0]?.description
  );

  const cv = useSelector((state) => state.home?.homeData[0]?.cv);
  const dispatch = useDispatch();
  const element = {
    function: "picture",
    modalTitle: "Modifiez votre photo",
    element: "cv",
    method: "POST", // Pour récuperer dynamiquement la methode lors de la soumission du formulaire
    value: cv,
  };
  const handleClick = () => {
    dispatch(setModalData(element));
    dispatch(openModal());
    dispatch(setDataInput({ name: "picture", value: "" }));
  };
  return (
    <li className="card card-2 col-2">
      <article className="card">
        <span className="card-icon" onClick={() => handleClick()}>
          <i className={`fa-solid fa-pen-to-square`}></i>
        </span>
        <h4>CV</h4>
        <p>
          <a href={cv}>Télécharger votre cv</a>
        </p>
      </article>
      <Card
        title={"Title"}
        modalTitle={"Modification du titre"}
        description={title}
        value={title}
        elementDom={"title"}
      />
      <Card
        title={"Description"}
        value={description}
        description={description}
        elementDom={"description"}
        modalTitle={"Modification de la description"}
      />
    </li>
  );
}
