import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menuOpened: false,
  },
  reducers: {
    toggleMenu: (state) => {
      state.menuOpened = !state.menuOpened;
    },
  },
});
// Export des actions générées
export const { toggleMenu } = menuSlice.actions;

// Export du reducer pour le store

export default menuSlice.reducer;
