import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { filterCommentButtons } from "../../api/commentSection";
import { setDataButton } from "../../store/features/comments";
import { FilterButton } from "./FilterButton";
import { ButtonConfirmedFilter } from "./ButtonConfirmedFilter";

export function Buttons() {
  // Récupérer les filtres depuis Redux
  const category = useSelector((state) => state.comment.categoryFilter);
  const isConfirmed = useSelector((state) => state.comment.confirmationFilter);

  const [defaultId, setDefaultId] = useState(category);
  const [confirmedActivebutton, setConfirmedActiveButton] =
    useState(isConfirmed);
  const dispatch = useDispatch();

  useEffect(() => {
    const request = async () => {
      const data = await filterCommentButtons();
      if (data) {
        dispatch(setDataButton(data));
      }
    };
    request();
  }, []);

  const buttonsData = useSelector((state) => state?.comment?.dataButton);
  return (
    <>
      {" "}
      <div className="d-flex boutons gap-3">
        <ButtonConfirmedFilter
          id={"all"}
          label={"Tous"}
          isConfirmed={confirmedActivebutton}
          setIsConfirmed={setConfirmedActiveButton}
          filteredId={defaultId}
        />
        <ButtonConfirmedFilter
          id={"confirmed"}
          label={"Confirmés"}
          isConfirmed={confirmedActivebutton}
          setIsConfirmed={setConfirmedActiveButton}
          filteredId={defaultId}
        />
        <ButtonConfirmedFilter
          id={"notConfirmed"}
          label={"À Confirmer"}
          isConfirmed={confirmedActivebutton}
          setIsConfirmed={setConfirmedActiveButton}
          filteredId={defaultId}
        />
      </div>
      <div className="d-flex boutons gap-3">
        {buttonsData?.map(({ id, label }, index) => (
          <FilterButton
            key={index}
            id={id}
            label={label}
            buttonId={id}
            defaultId={defaultId}
            setDefaultId={setDefaultId}
          />
        ))}
      </div>
    </>
  );
}
