import { CustomModal } from "../Modal/Modal";
import { useEffect, useState } from "react";
import { fetchForgotPassword, isValidEmail } from "./../../api/loginAPI.js";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  setIsApmtyInput,
  setModalMessage,
} from "../../store/features/modalSlice.js";
import {
  resetDataFP,
  updateValueFP,
} from "../../store/features/adminLoginSlice.js";

export function ForgotPassword() {
  const [isFocused, setIsFocused] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const data = useSelector((state) => state.modal);
  const message = data?.modalMessage;
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();
  const dataInput = useSelector((state) => state.login.dataFP);
  useEffect(() => {
    // Vérifie que tous les champs sont remplis
    const allFieldsFilled = Object?.values(dataInput)?.every(
      (value) => value && value?.trim() !== ""
    );
    dispatch(setIsApmtyInput(!allFieldsFilled));
  }, [dataInput, dispatch]);

  const handleConfirm = () => {
    setSending(true);
    // Action à exécuter lors de la confirmation
    const formSubmit = async () => {
      if (!isValidEmail(dataInput.email)) {
        dispatch(setModalMessage("Erreur de syntaxe."));
        setSending(false);
      } else {
        try {
          const response = await fetchForgotPassword(dataInput.email);
          const success = response.status === "201";
          if (success) {
            setSending(false);

            setIsSent(true);
            dispatch(
              setModalMessage(
                "Un lien à été envoyé sur votre adresse email pour la réinitialisation du mot de passe."
              )
            );
          } else {
            setSending(false);

            setIsSent(false);
            dispatch(setModalMessage(response.error));
          }
        } catch (err) {
          dispatch(
            setModalMessage(
              "Une erreur est survenue lors de la requête, Veuillez réessayer plutard. "
            )
          );
          setSending(false);
        }
      }
    };

    setTimeout(() => formSubmit(), 3000);
  };
  const handleclick = () => {
    dispatch(openModal());
  };
  const handleInputChange = (value) => {
    dispatch(updateValueFP(value));
  };

  return (
    <>
      <span className="forgot-password" onClick={() => handleclick()}>
        Mot de pass oublié ?
      </span>
      <CustomModal
        title="Réinitialisation du mot de passe"
        onConfirm={() => handleConfirm()}
        isSent={isSent}
        resetValues={() => dispatch(resetDataFP())}
        sending={sending}
      >
        <form>
          <div className="form-group">
            <label
              htmlFor="verifyEmail"
              className={`form-label floating-label ${
                isFocused ? "focused" : ""
              }`}
            >
              Email
            </label>
            <input
              type="email"
              className="form-control"
              name="verifyEmail"
              id="verifyEmail"
              placeholder={isSent ? dataInput.email : " "}
              onChange={(e) => handleInputChange(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              required
              disabled={isSent}
            />
          </div>
        </form>

        {message && (
          <p className={isSent ? "success" : "modalError"}>{message}</p>
        )}
      </CustomModal>
    </>
  );
}
