import ViewsChart from "../Chart/Graphique";
import LoadChart from "../Chart/LoadChart";
import { Card } from "./Card";

export function Cards() {
  return (
    <ul className="d-flex list-inline w-100 cards-top">
      <Card />
      <li className="card p-2">
        <h3>Statistiques des Articles</h3>
        <LoadChart />
      </li>
      <li className="card p-2 portfolio">
        <ViewsChart />
      </li>
    </ul>
  );
}
