import { useDispatch, useSelector } from "react-redux";
import { CustomModal } from "../../Modal/Modal";

import { resetValue, UpdateHome } from "../../../store/features/home";
import { useEffect, useState } from "react";
import {
  closeModal,
  setIsApmtyInput,
  setIsSent,
  setModalMessage,
} from "../../../store/features/modalSlice";

import { refreshAccessToken } from "../../../api/getAll";
import { deleteToken } from "../../../store/features/adminLoginSlice";
import { Input } from "./Input";
import { Picture } from "./Picture";
import { fetchUpdateText, fetchUpdatPic } from "../../../api/home";

export function ModalContent() {
  const element = useSelector((state) => state?.home?.modalData) || {};
  const dataInput = useSelector((state) => state.home?.dataInput);
  const [sending, setSending] = useState(false);
  const [file, setFile] = useState(null);

  const data = useSelector((state) => state.modal);
  const message = data?.modalMessage;
  const title = element?.modalTitle,
    method = element?.method,
    cardElement = element?.element,
    elementFunction = element?.function,
    value = dataInput[cardElement];

  const dispatch = useDispatch();
  useEffect(() => {
    // Vérifie que tous les champs sont remplis
    const allFieldsFilled = Object?.values(dataInput)?.every(
      (value) => value && value?.trim() !== ""
    );
    dispatch(setIsApmtyInput(!allFieldsFilled));
  }, [dataInput, dispatch]);
  const switchFetch = async (elementfunction, element) => {
    if (elementFunction === "text") {
      return fetchUpdateText(dataInput);
    } else {
      const formData = new FormData();
      if (element === "cv") {
        formData.append("home_cv", file);
      } else {
        formData.append("home_img", file);
      }

      return fetchUpdatPic(formData);
    }
  };
  const handleCloseModal = () => {
    dispatch(resetValue());
    dispatch(closeModal());
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
    dispatch(setIsApmtyInput(true));
  };
  const fetchSuccess = (data) => {
    dispatch(UpdateHome(data));
    dispatch(setIsSent(true));
    setSending(false);
    handleCloseModal();
  };
  const fetchError = () => {
    dispatch(setIsSent(false));
    dispatch(setModalMessage("Erreur lors de la soumission du formulaire !!"));
    setSending(false);
  };

  const onConfirm = () => {
    setSending(true);
    const update = async () => {
      try {
        const response = await switchFetch(elementFunction, cardElement);
        const status = response.status;
        if (status === "201") {
          fetchSuccess(response.element);
        } else if (status === "401" && response.error === "Token Expiré") {
          // refresh token
          const fetchRefreshToken = await refreshAccessToken();
          if (fetchRefreshToken.status === "201") {
            const token = JSON.stringify(fetchRefreshToken.accessToken);
            sessionStorage.setItem("token", token);
            const request = await switchFetch(elementFunction, cardElement);
            if (request.status === "201") {
              fetchSuccess(request.element);
            } else {
              const error = request.error;
              fetchError();
              dispatch(setModalMessage(error));
            }
          } else {
            fetchError();
            dispatch(deleteToken());
          }
        } else {
          const error = response.error;
          fetchError();
          dispatch(setModalMessage(error));
        }
        setSending(false);
      } catch (error) {
        dispatch(
          setModalMessage(
            "Erreur lors de la soummission du formulaire, Réessayez plutard"
          )
        );
        setSending(false);
      }
    };

    setTimeout(() => update(), 3000);
  };

  return (
    <CustomModal
      title={title}
      isSent={false}
      sending={sending}
      resetValues={() => dispatch(resetValue())}
      onConfirm={() => onConfirm()}
    >
      {elementFunction === "text" ? (
        <Input value={value} />
      ) : (
        <Picture image={value} setFile={setFile} />
      )}
      {message && (
        <p
          className={
            message === "Modification Reussie !!" ? "success" : "error"
          }
        >
          {message}
        </p>
      )}
    </CustomModal>
  );
}
