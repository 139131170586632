import { createSlice } from "@reduxjs/toolkit";

export const userLoginSlice = createSlice({
  name: "login",
  initialState: {
    accessToken: null,
    refreshToken: null,
    session: null,
    dataFP: {
      email: "",
    },
  },
  reducers: {
    loadToken: (state, actions) => {
      state.accessToken = actions.payload;
      state.session = true;
    },
    addRefreshToken: (state, actions) => {
      state.refreshToken = actions.payload;
      state.session = true;
      localStorage.setItem("refreshToken", actions.payload);
    },
    deleteToken: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.session = false;
      sessionStorage.getItem("token")
        ? sessionStorage.removeItem("token")
        : localStorage.removeItem("token");
    },
    // FP: Forgot Password
    resetDataFP: (state) => {
      state.dataFP.email = "";
    },
    updateValueFP: (state, actions) => {
      state.dataFP.email = actions.payload;
    },
  },
});

export const {
  loadToken,
  addRefreshToken,
  deleteToken,
  resetDataFP,
  updateValueFP,
} = userLoginSlice.actions;
export default userLoginSlice.reducer;
