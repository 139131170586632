import { useDispatch, useSelector } from "react-redux";
import woman from "./../../assets/images/woman.jpg";
import React from "react";
import { toggleMenu } from "../../store/features/MenuSlice";
export function Header() {
  const imgUrl = useSelector(
    (state) => state?.settings?.adminProfile[6]?.value
  );

  const url = imgUrl ? imgUrl : woman;
  const pseudo = useSelector(
    (state) => state?.settings?.adminProfile[0]?.value
  );
  const dispatch = useDispatch();
  const handletoggleMenu = () => {
    dispatch(toggleMenu());
  };
  return (
    <header className="sticky-top p-md-3 px-2 d-flex">
      <h1 className="m-0 d-flex" onClick={() => handletoggleMenu()}>
        Dashbord
      </h1>
      <nav className="d-flex">
        <ul className="d-flex list-inline m-0">
          <li id="portfolio">
            <a href="https://www.rouguiz.com/">
              <i className="fa-regular fa-eye"></i>
              <span>Portfolio</span>
            </a>
          </li>
          <li>{pseudo}</li>
          <li className="rounded-circle">
            <img src={url} alt="developpeuse web" />
          </li>
        </ul>
      </nav>
    </header>
  );
}
