import React from "react";
import * as pdfjs from "pdfjs-dist/webpack"; // Importation correcte
import developpeuseImage from "./../../assets/images/developpeuse_web.webp";
import { useDispatch, useSelector } from "react-redux";
import { setDataInput, setModalData } from "../../store/features/home";
import { openModal } from "../../store/features/modalSlice";

// Configuration du worker PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export function HomePicture() {
  const dispatch = useDispatch();
  const imageUrl =
    useSelector((state) => state?.home?.homeData[0]?.picture) ||
    developpeuseImage;
  const element = {
    function: "picture",
    modalTitle: "Modifiez votre photo",
    element: "picture",
    method: "POST", // Pour récuperer dynamiquement la methode lors de la soumission du formulaire
    value: imageUrl,
  };
  const handleClick = () => {
    dispatch(setModalData(element));
    dispatch(openModal());
    dispatch(setDataInput({ name: "picture", value: imageUrl }));
  };
  return (
    <>
      <li className="card p-0 img">
        <span className="card-icon" onClick={() => handleClick()}>
          <i className="fa-solid fa-pen-to-square"></i>
        </span>
        <img src={imageUrl} alt="developpeur web" />
      </li>
    </>
  );
}
