import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsSent, setModalMessage } from "../../../store/features/modalSlice";
import { updateInputValue } from "../../../store/features/resume";

export function Skills({ title, icon, id, setFile }) {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState(icon);

  const handleFileChange = (event) => {
    dispatch(setModalMessage(""));

    const file = event.target.files[0];
    if (file) {
      setFile(file);
      dispatch(updateInputValue({ name: "icon", value: "success" }));
      const fileURL = URL.createObjectURL(file);
      setUploadedFile(fileURL);
    }
  };
  const handleChange = (value) => {
    dispatch(updateInputValue({ name: "title", value }));
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
  };
  return (
    <div className="ModalSkills">
      {!uploadedFile ? (
        <div className="picture d-flex">
          <span className="icon">
            <i className="fa-solid fa-image"></i>
          </span>
          <span className="bouton">
            <input
              type="file"
              name="skill_img"
              id="file"
              onChange={handleFileChange}
            />
            <button> + Ajouter photo</button>
          </span>
          <span className="extensions"> jpg, png, webp, svg : 10mo max</span>
        </div>
      ) : (
        <div className="svg-preview">
          <span
            className="icon"
            onClick={() => {
              dispatch(updateInputValue({ name: "icon", value: null }));

              setUploadedFile(null);
            }}
          >
            <i className="fa-solid fa-trash-can"></i>
          </span>
          <img src={uploadedFile} alt="Preview" style={{}} />
        </div>
      )}

      <div className="form-group">
        <label htmlFor="title" className="form-label">
          Titre
        </label>
        <input
          className="form-control"
          type="text"
          id="title"
          name="title"
          value={title}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
}
