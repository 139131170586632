import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./features/modalSlice";
import loginReducer from "./features/adminLoginSlice";
import resumeReducer from "./features/resume";
import projectReducer from "./features/project";
import blogReducer from "./features/blog";
import commentsReducer from "./features/comments";
import settingsReducer from "./features/settings";
import menuReducer from "./features/MenuSlice";
import homeReducer from "./features/home";
export const store = configureStore({
  reducer: {
    modal: modalReducer, // Associe le reducer counter à la clé "modal"
    login: loginReducer,
    resume: resumeReducer,
    project: projectReducer,
    blog: blogReducer,
    comment: commentsReducer,
    settings: settingsReducer,
    menu: menuReducer,
    home: homeReducer,
  },
});
