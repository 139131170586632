import { useSelector } from "react-redux";
import { inputConfig } from "../../../data/DataResume";
import { InputEducation } from "./inputEducation";
// Ici on appelle le "InputEducation" pour lui passer les données qu'on recupere depuis le slice 'Resume'
export function Education() {
  const dataInputConfig = useSelector((state) => state.resume.dataInput);

  const inputArray =
    inputConfig(
      dataInputConfig?.id,
      dataInputConfig?.title,
      dataInputConfig?.year,
      dataInputConfig?.description,
      dataInputConfig?.schoolName,
      dataInputConfig?.schoolLink
    ) || [];

  return (
    <div>
      <form>
        {inputArray?.map(({ name, label, type, value }, index) => (
          <div key={index} className="form-group">
            <InputEducation
              name={name}
              value={value}
              label={label}
              type={type}
            />
          </div>
        ))}
      </form>
    </div>
  );
}
