export const cardHome = [
  {
    title: "Commentaires à confirmer",
    count: 5,
    new: true,
    mainText: "Aucun nouveau Commentaire",
    actions: [
      {
        icon: "fa-solid fa-comment-dots",
        text: "Tous les commentaires",
        url: "/comments",
      },
    ],
  },
  {
    title: "Nombre d'article",
    count: 3,
    new: false,
    actions: [
      {
        icon: "fa-solid fa-square-plus",
        text: "Ajouter un article",
        url: "/articles/add",
      },
      {
        icon: "fa-regular fa-newspaper",
        text: "Tous les articles",
        url: "/blog",
      },
    ],
  },
];

export const cardData = [
  {
    icon: "fa-pen-to-square",
    title: "Titre",
    description: "Front-end developer",
  },
  {
    icon: "fa-pen-to-square",
    title: "Description",
    description:
      "💻 Développeuse web passionnée, je combine mes connaissances en front-end 🌐 et back-end 🔧 pour créer des interfaces interactives et dynamiques avec React ⚛️. Avec des bases solides en PHP 🐘, je m'efforce de concevoir des systèmes back-end fiables et évolutifs pour offrir des expériences utilisateur de qualité ⭐.",
  },
];
