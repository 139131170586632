// Tableau pour les données personnelles (Personal Data)
export const personalData = [
  {
    id: "personal_1",
    label: "Pseudo",
    value: "_JD",
    editable: true,
    modalTitle: "Mettez à jour votre pseudo",
  },
  {
    id: "personal_2",
    label: "Nom",
    value: "John Doe",
    editable: true,
    modalTitle: "Changez votre nom complet",
  },
  {
    id: "personal_3",
    label: "Âge",
    value: "23 ans",
    editable: false,
    modalTitle: "Détails sur votre âge",
  },
  {
    id: "personal_4",
    label: "Genre",
    value: "Femme",
    editable: false,
    modalTitle: "Genre renseigné",
  },
  {
    id: "personal_5",
    label: "Profession",
    value: "Développeuse Web",
    editable: true,
    modalTitle: "Mettez à jour votre profession",
  },
  {
    id: "personal_6",
    label: "Nationalité",
    value: "Guinéenne",
    editable: true,
    modalTitle: "Actualisez votre nationalité",
  },
  {
    id: "personal_7",
    label: "Photo du Profile",
    value: "url",
    editable: false,
    modalTitle: "Modifier votre photo du profil",
  },
];

// Tableau pour l'adresse et le contact (Address and Contact)
export const addressContact = [
  {
    id: "contact_1",
    label: "Adresse",
    value: "6 Rue Facultative, 00000 France",
    editable: true,
    modalTitle: "Modifiez votre adresse complète",
  },
  {
    id: "contact_2",
    label: "Pays",
    value: "France",
    editable: false,
    modalTitle: "Votre pays de résidence",
  },
  {
    id: "contact_3",
    label: "Téléphone",
    value: "+33 000000000",
    editable: true,
    modalTitle: "Ajoutez ou modifiez votre numéro de téléphone",
  },
];

// Tableau pour les paramètres du compte (Account Settings)
export const accountSettings = [
  {
    id: "account_2",
    label: "Email",
    value: "johndoe559@gmail.com",
    editable: true,
    modalTitle: "Mettez à jour votre adresse email",
  },
  {
    id: "account_3",
    label: "Mot de passe",
    value: "********",
    editable: true,
    modalTitle: "Changez votre mot de passe en toute sécurité",
  },
  {
    id: "account_4",
    label: "Membre depuis",
    value: "2024-10-01 03:50",
    editable: false,
    modalTitle: "Date d'inscription",
  },
];

// Configuration des champs pour chaque élément modifiable

export function getModalFieldsConfig(id, label, value) {
  const modalFieldsConfig = {
    // Configuration pour l'adresse et le contact
    contact_1: [
      {
        name: "country",
        label: "Pays",
        type: "select",
        placeholder: "Sélectionnez votre pays",
        options: ["pays"], // Liste des pays triés dynamiquement ici
      },
      {
        name: "code_postal",
        label: "Code Postal",
        type: "text",
        placeholder: "Ex: 45000",
      },
      {
        name: "city",
        label: "Ville",
        type: "text",
        placeholder: "Entrez votre ville",
      },
      {
        name: "street",
        label: "Rue",
        type: "text",
        placeholder: "Entrez votre rue",
      },
    ],

    // Configuration pour le numéro de téléphone (contact_3)
    contact_3: [
      {
        name: "phone_country_code",
        label: "Code du pays (téléphone)",
        type: "select",
        placeholder: "Sélectionnez le code du pays",
        options: [], // Liste des codes pays à remplir dynamiquement
      },
      {
        name: "phone_number",
        label: "Numéro de téléphone",
        type: "text",
        placeholder: "Entrez votre numéro de téléphone sans le code pays",
      },
    ],

    // Configuration pour le mot de passe avec plusieurs champs
    account_3: [
      {
        name: "currentPassword",
        label: "Ancien mot de passe",
        type: "password",
        placeholder: "Entrez votre ancien mot de passe",
      },
      {
        name: "newPassword",
        label: "Nouveau mot de passe",
        type: "password",
        placeholder: "Entrez votre nouveau mot de passe",
      },
      {
        name: "confirmPassword",
        label: "Confirmez le mot de passe",
        type: "password",
        placeholder: "Confirmez votre nouveau mot de passe",
      },
    ],

    // Configuration pour l'email avec vérification par mot de passe
    account_2: [
      {
        name: "email",
        label: "Nouvel email",
        type: "email",
        placeholder: "Entrez votre nouvel email",
      },
      {
        name: "password",
        label: "Mot de passe",
        type: "password",
        placeholder: "Entrez votre mot de passe pour confirmer",
      },
    ],

    // Configuration pour la nationalité avec auto-complétion
    personal_6: [
      {
        name: "nationality",
        label: "Nationalité",
        type: "select",
        placeholder: value,
        autoComplete: true, // Flag pour auto-complétion de la nationalité
      },
    ],

    // Configuration générique pour les autres champs
    default: [{ name: label, label: label, type: "text", placeholder: value }],
  };
  return modalFieldsConfig[id] || modalFieldsConfig.default;
}
export function dataFormConfig(id) {
  const modalFieldsConfig = {
    // Configuration pour l'adresse et le contact
    contact_1: {
      country: "",

      code_postal: "",

      city: "",

      street: "",
    },

    // Configuration pour le numéro de téléphone (contact_3)
    contact_3: {
      phone_country_code: "",
      phone_number: "",
    },

    // Configuration pour le mot de passe avec plusieurs champs
    account_3: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    // Configuration pour l'email avec vérification par mot de passe
    account_2: {
      email: "",
      password: "",
    },

    // Configuration générique pour les autres champs
    default: { value: "" },
  };
  return modalFieldsConfig[id] || modalFieldsConfig.default;
}
export function calculateAge(birthDate) {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();

  // Vérifie si l'anniversaire n'est pas encore passé cette année
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
}
