
import google from './../../assets/svg/google-icon.svg'
import github from './../../assets/svg/github-icon.svg'
export function AdminSocialLink({description}){
    const connextion = "google"
    const statut     = true
    const url = connextion ==="google" ? google : github
    const alt = connextion === "google" ? "google " : "github" 
    const title = connextion ==="google" ? "Google " : "Github" 
    const icon =  statut ? "check" : "minus"
    return(
        <li className="cardSocialLink d-flex  col-12">
            <div className='d-flex'> 
                <span className='imgContainer'>
                    <img src={url} alt={`${alt} icon`} />
                </span>
                <h4>{title}</h4>
            </div>
            <p>
                <span className={`${statut ? 'green' : "red"} icon` }>
                    <i className={`fa-solid fa-${icon}`} ></i>
                </span>
                    {description}
            </p>
            <button className='btn btn-primary'>
                Dissocier
            </button>
        </li>
    )

}