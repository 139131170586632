export function inputConfig(
  id,
  title,
  year,
  description,
  schoolName,
  SchoolLink
) {
  const dataResume = {
    // Configuration pour l'adresse et le contact
    education: [
      {
        name: "title",
        label: "Titre de la formation",
        type: "input",
        value: title,
      },
      {
        name: "year",
        label: "Année",
        type: "year",
        value: year,
      },
      {
        name: "description",
        label: "Description",
        type: "textarea",
        value: description,
      },
      {
        name: "schoolName",
        label: "Nom de l'ecole",
        type: "text",
        value: schoolName,
      },
      {
        name: "schoolLink",
        label: "Lien de l'ecole",
        type: "text",
        value: SchoolLink,
      },
    ],
  };
  return dataResume.education;
}
