import { useDispatch, useSelector } from "react-redux";
import { Picture } from "./Picture";
import { inputConfig } from "../../../data/dataProject";
import { Input } from "./Input";
import { CustomModal } from "../../Modal/Modal";
import { DeleteElement } from "./DeletetElement";
import { useEffect, useState } from "react";
import {
  closeModal,
  setIsApmtyInput,
  setIsSent,
  setModalMessage,
} from "../../../store/features/modalSlice";
import {
  addOrUpdateProject,
  deleteProjectLocal,
  resetValue,
} from "../../../store/features/project";
import {
  fetchAddProject,
  fetchDeleteProject,
  fetchUpdateProject,
} from "../../../api/projectSection";
import { deleteToken } from "../../../store/features/adminLoginSlice";
import { refreshAccessToken } from "../../../api/getAll";

export function ModalContent() {
  const [sending, setSending] = useState(false);
  const [file, setFile] = useState(null);

  const modalData = useSelector((state) => state.project.modalData);
  const dataInput = useSelector((state) => state.project.dataInput);
  const data = useSelector((state) => state.modal);
  const message = data?.modalMessage;
  const method = modalData.method,
    id = modalData?.id,
    modalFunction = modalData.function;
  const inputArray = inputConfig(
    dataInput?.id,
    dataInput?.title,
    dataInput?.technologies,
    dataInput?.description,
    dataInput?.website,
    dataInput?.github
  );

  const dispatch = useDispatch();
  useEffect(() => {
    // Vérifie que tous les champs sont remplis
    const allFieldsFilled = Object?.values(dataInput)?.every(
      (value) => value && value?.trim() !== ""
    );
    dispatch(setIsApmtyInput(!allFieldsFilled));
  }, [dataInput, dispatch]);
  const handleCloseModal = () => {
    dispatch(resetValue());
    dispatch(closeModal());
    dispatch(setModalMessage(""));
    dispatch(setIsSent(false));
    dispatch(setIsApmtyInput(true));
  };

  const fetchSuccess = (id, addedElement) => {
    const newLocaldata = addedElement ? addedElement : dataInput;
    method === "DELETE"
      ? dispatch(deleteProjectLocal(id))
      : dispatch(addOrUpdateProject({ id, newProject: newLocaldata }));

    dispatch(setIsSent(true));

    setSending(false);
    handleCloseModal();
  };
  const fetchError = () => {
    dispatch(setIsSent(false));
    dispatch(setModalMessage("Erreur lors de la soumission du formulaire !!"));
    setSending(false);
  };
  const switchFetch = async (id, method) => {
    const formData = new FormData();
    formData.append("project_img", file);
    formData.append("title", dataInput.title);
    formData.append("technologies", dataInput.technologies);
    formData.append("description", dataInput.description);
    formData.append("website", dataInput.website);
    formData.append("github", dataInput.github);
    switch (method) {
      case "UPDATE":
        return await fetchUpdateProject(id, formData);
        break;
      case "POST":
        return await fetchAddProject(formData);
        break;
      case "DELETE":
        return await fetchDeleteProject(id);
        break;
      default:
        fetchError();
    }
  };
  const onConfirm = () => {
    setSending(true);
    const update = async () => {
      try {
        const response = await switchFetch(id, method);
        const status = response.status;
        if (status === "201") {
          fetchSuccess(
            response.element ? response.element.id : id,
            response.element
          );
        } else if (status === "401" && response.error === "Token Expiré") {
          // refresh token
          const fetchRefreshToken = await refreshAccessToken();
          if (fetchRefreshToken.status === "201") {
            const token = JSON.stringify(fetchRefreshToken.accessToken);
            sessionStorage.setItem("token", token);

            const request = await switchFetch(id, method);

            if (request.status === "201") {
              fetchSuccess(
                response.element ? response.element.id : id,
                response.element
              );
            } else {
              const error = request.error;
              fetchError();
              dispatch(setModalMessage(error));
            }
          } else {
            fetchError();
            dispatch(deleteToken());
          }
        } else {
          const error = response.error;
          fetchError();
          dispatch(setModalMessage(error));
        }
        setSending(false);
      } catch (error) {
        dispatch(
          setModalMessage(
            "Erreur lors de la soummission du formulaire, Réessayez plutard"
          )
        );
        setSending(false);
      }
    };

    setTimeout(() => update(), 3000);
  };
  return (
    <CustomModal
      title={modalData?.modalTile}
      resetValues={() => dispatch(resetValue())}
      sending={sending}
      onConfirm={() => onConfirm()}
    >
      {modalFunction === "addOrUpdate" ? (
        <form>
          <Picture image={dataInput?.image} setFile={setFile} />
          {inputArray.map(({ name, label, type, value }, index) => (
            <div key={index} className="form-group">
              <Input
                key={index}
                name={name}
                label={label}
                value={value}
                type={type}
              />
            </div>
          ))}
        </form>
      ) : (
        <DeleteElement />
      )}
      {message && (
        <p
          className={
            message === "Modification Reussie !!" ? "success" : "error"
          }
        >
          {message}
        </p>
      )}
    </CustomModal>
  );
}
